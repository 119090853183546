<template>
  <div class="modal" style="display:block">

    <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}">
        <!-- question -->
        <h2 v-if="hasError === null">{{ current.titre }}</h2>
        <p class="blue" v-if="hasError === null">{{ current.libelle }}</p>

        <div v-if="hasError === true">
          <p><strong><big class="red">PAS TOUT A FAIT !</big></strong> <br>
          <span v-html="current.messages.erreur"></span></p>
        </div>
        <div v-if="hasError === false">
          <h2>BRAVO !</h2>
          <p><span v-html="current.messages.bravo"></span></p>
        </div>

        <div class="poubelle" v-if="hasError !== false">
            <img class="bg" src="img/indices/poubelle.jpg" alt="">
            <button v-for="(reponse, index) in current.reponses" :key="index"
                type="button" class="btn-img"
                :id="index"
                :disabled="hasError === false"
                @click="clicRadio($event)">
                <img
                    :src="reponse.image"
                    :srcset="reponse.imagex2 + ' 2x'"
                    :alt="reponse.alt">
            </button>
        </div>

        <div v-if="hasError === false">
            <template v-for="(reponse, index) in current.reponses">
                <template v-if="reponse.bonne_reponse === true">
                    <img class="d-none" :key="index"
                    :src="reponse.image"
                    :alt="reponse.alt">
                    <img :key="'ok'+index" class="poubelleok" src="img/indices/poubelle-ok.jpg" alt="">
                </template>
            </template>
        </div>

        <p v-if="hasError === false" class="legende" v-html="current.messages.legende"></p>
    </div>

    <!-- MODAL FOOTER -->
    <div class="modal-footer">
      <!-- BTN VALIDER -->
      <button v-if="hasError !== false" type="button" class="btn btn-blue align-center" @click="checkSelectedReponses()"><span>Valider</span></button>

      <button v-if="hasError === false && !isAlreadyInDossier" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter aux indices</span></button>

      <!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->
      <button v-if="hasError === false && isAlreadyInDossier" type="button" class="btn btn-blue align-left" @click="ecranSuivant()"><span>Retour</span></button>
    </div>
  </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'QuizRadioButton',
  data() {
    return {
      quizList: jsonQuiz,
      currentQuestion: 5, // on a un seul quiz de ce type
      hasError: null,
      nbBonnesReponsesSelected: 0,
      isAlreadyInDossier: false,
      isRight: true,
    }
  },
  computed: {
    current() {
      return this.quizList[this.currentQuestion]
    },
    bonnesReponsesIds() {
      let bonnes_reponses = [];

      for (let i = 0; i < this.current.reponses.length; i++) {
        if (this.current.reponses[i].bonne_reponse) {
          bonnes_reponses.push(parseInt(i,10));
        }
      }

      return bonnes_reponses
    }
  },
	methods : {
		close() {
			this.$emit('close')
		},

    clicRadio(e) {
      let rep = e.target.parentNode
      let others_selected = document.querySelectorAll('.selected');

      for (let i = 0; i < others_selected.length; i++) {
        others_selected[i].classList.remove('selected')
      }
      rep.classList.add('selected')
    },

    checkSelectedReponses() {
      this.hasError = null

      // on parcourt les reponses selectionnees
      let selected = document.getElementsByClassName('selected')
      for (let j=(selected.length - 1); j>=0; j--) {

        // index de la reponse
        let rep_id = parseInt(selected[j].getAttribute('id'),10)

        // si la réponse sélectionnée est parmi les bonnes reponses
        if ((this.bonnesReponsesIds).indexOf(rep_id) !== -1) {
          selected[j].classList.add('right')
          selected[j].classList.remove('selected')
          this.nbBonnesReponsesSelected++
        } else {
          selected[j].classList.add('wrong')
          selected[j].classList.remove('selected')
        }
      }

      // s'il manque des bonnes réponses
      if (this.nbBonnesReponsesSelected != this.bonnesReponsesIds.length) {
        this.hasError = true
      } else {

        // toutes les bonnes réponses OK
        this.hasError = false

        // on reset la couleur des mauvaises reponses pour ne laisser que les bonnes reponses en vert
        let mauvaises_reponses = document.getElementsByClassName('wrong')
        for (let j=(mauvaises_reponses.length - 1); j>=0; j--) {
          mauvaises_reponses[j].classList.add('defaut')
          mauvaises_reponses[j].classList.remove('wrong')
        }
      }
    },

    addToDossier() {
      this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
      this.isAlreadyInDossier = true
    },

    ecranSuivant() {
      if (this.current.enchaineQuizIndex != undefined) {

        let nextQuiz = this.quizList[this.current.enchaineQuizIndex]
        EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);

      } else {
        this.close()
      }
    }
	}
}
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    h2 {
        font-size: 2.2rem;
        font-weight: 500;
        margin: 0 0 1rem;
    }

    .poubelle {
        position: relative;
    }

    .poubelleok {
        height: 37rem;
        width: 66rem;
        margin: auto;
    }

    .d-none {
        display: none;
    }

    .bg {
        max-width: 100%;
        max-height: 42rem;
    }

    .btn-img {
        border: 5px solid transparent;
        border-radius: 1.2rem;
        border-bottom-width: 3px;
        padding: 0;
        position: absolute;
        z-index: 100;

        &.selected {
            border-color: $blue;
        }

        &:nth-child(2) {
            top: 3rem;
            left: 2rem;
        }

        &:nth-child(3) {
            bottom: 4rem;
            left: 2rem;
        }

        &:nth-child(4) {
            top: 3rem;
            right: 2rem;
        }

        &:nth-child(5) {
            bottom: 4rem;
            right: 2rem;
        }
    }
</style>